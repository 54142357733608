/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {

.header-area {
	padding: 25px 15px;
}
.main-menu nav > ul > li {
	margin: 0 9px;
}
.menu-bar {
	margin-right: 10px;
	margin-left: 10px;
}
.cta-img {
	margin-bottom: 0;
}
.cta-img img {
	width: 100%;
}
.team-active .owl-nav div {
	left: 0;
}
.team-active .owl-nav div.owl-next {
	left: auto;
	right: 0;
}
.faq-img {
	margin-left: 0;
}
.faq-img img {
	width: 100%;
}
.gallery-active .owl-nav div {
	left: 0;
}
.gallery-active .owl-nav div.owl-next {
	left: auto;
	right: 0;
}
.client-active .owl-nav div {
	left: 0;
}
.client-active .owl-nav div.owl-next {
	left: auto;
	right: 0;
}

}


/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {

.single-features {
	padding-right: 0;
}
.features-content h3 {
	font-size: 20px;
}
.features-icon {
	margin-right: 15px;
}
.client-wrapper {
	padding: 23px 15px;
}
.features-1-img img {
	width: 100%;
}
.features-1-img {
	margin-left: 0;
	margin-top: 0;
}
.section-title h1 {
	font-size: 46px;
}
.section-1-title h1 {
	font-size: 46px;
}
.services-text p {
	padding: 0;
}
.faq-img {
	margin-left: 0;
}
.faq-img img {
	width: 100%;
}
.testimonial-img img {
	width: 100%;
}
.testimonial-img {
	margin-left: 0;
}
.cta-img img {
	width: 100%;
}
.cta-img {
	margin-bottom: 0;
}
.blog-text > h4 {
	padding-right: 0;
}
.blog-meta span > a {
	padding: 4px 9px;
}
.blog-meta span {
	margin-right: 7px;
}
.footer-wrapper{
	padding-left: 0;
	padding-right: 0;
}
.team-active .owl-nav div {
	left: 0;
}
.team-active .owl-nav div.owl-next {
	left: auto;
	right: 0;
}
.testimonial-active .owl-nav div {
	display: none;
}
.testimonial-text p {
	margin-right: 0;
}
.faq-box .btn-link::before {
	right: 20px;
}
.about-wrapper {
	padding-right: 0;
}
.about-img {
	margin-left: 0;
}
.about-img img{width:100%;}
.our-features-wrapper {
	padding: 80px 30px 72px;
}
.area-title h1 {
	font-size: 48px;
}
.our-services-wrapper {
	padding: 55px 20px 60px 20px;
}
.client-active .owl-nav div {
	left: 0;
}
.client-active .owl-nav div.owl-next {
	left: auto;
	right: 0;
}
.pricing-bg {
	padding-left: 0;
}
.pricing-wrapper {
	padding: 60px 20px 50px 20px;
}
.price-count h5 {
	font-size: 12px;
}
.download-img {
	margin-left: 0;
}
.download-img img {
	width: 100%;
}
.download-wrapper {
	padding-left: 0;
	margin-top: 0;
}
.header-area {
	padding: 25px 15px;
}
.main-menu nav > ul > li {
	margin: 0 9px;
}
.slider-content h1 {
	font-size: 70px;
}
.slider-text h1 {
	font-size: 70px;
}
.slider-content p {
	margin-right: 0;
}
.gallery-active .owl-nav div {
	left: 0;
}
.gallery-active .owl-nav div.owl-next {
	left: auto;
	right: 0;
}
.we-are {
	padding-bottom: 50px;
}
.features-02 .features-02-wrapper {
	margin-left: 0;
	margin-right: 0;
}
.features-3-img img {
	width: 100%;
}
.testimonial-2-wrapper {
	padding: 32px 15px 46px 15px;
}
.testimonial-2-img {
	margin-right: 8px;
}
.testimonial-2-text h4 {
	font-size: 16px;
}
.breadcrumb-area {
	padding-top: 200px;
	padding-bottom: 120px;
}
.features-1-content p {
	padding-right: 0;
}
.cta-text {
	padding-left: 0;
	padding-right: 0;
}
.services-2-details-img img {
	width: 100%;
}
.features-bg {
	padding: 70px 40px 42px 40px;
}
.portfolio-menu {
	margin-left: 0;
}
.project-content {
	padding: 21px 25px 26px;
}
.area-title {
	padding-left: 0;
	padding-right: 0;
}
.project-details-bg {
	padding-left: 0;
	padding-right: 0;
}
.single-pricing {
	padding: 50px 20px 56px 20px;
}
.pricing-2-list {
	padding-left: 0;
}
.single-blog .blog-text {
	padding: 60px 15px 50px 15px;
}
.single-blog .blog-meta {
	padding: 20px 10px 20px 10px;
}
.choose-img img {
	width: 100%;
}
.features-2-img img {
	width: 100%;
}
.features-03-wrapper {
	padding-left: 0;
}
.blog-3-area {
	padding-top: 115px;
}
.blog-share-icon a {
	margin-right: 15px;
}
.contact-address-title {
	padding-right: 0;
}
.single-1-team {
	margin-right: 0;
}
.slider-2-img {
	margin-left: -275px;
}
.header-3 .header-right::before {
	display: none;
}


}


/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
.single-features {
	padding-right: 0;
}
.features-1-img img {
	width: 100%;
}
.slider-1-height {
	min-height: 680px;
}
.slider-1-height {
	min-height: 680px;
}
.features-1-img {
	margin-left: 0;
	margin-top: 0;
}
.services-text p {
	padding: 0;
}
.team-active .owl-nav div {
	left: 0;
}
.team-active .owl-nav div.owl-next {
	left: auto;
	right: 0;
}
.faq-img {
	margin-left: 0;
}
.faq-img img {
	width: 100%;
}
.testimonial-img {
	margin-left: 0;
}
.testimonial-img img {
	width: 100%;
}
.cta-img {
	margin-bottom: 0;
}
.blog-meta span > a {
	padding: 4px 15px;
}
.newsletters-wrapper {
	padding-left: 15px;
	padding-right: 15px;
}
.about-wrapper {
	padding-right: 0;
}
.about-img {
	margin-left: 0;
}
.about-img img{width:100%;}

.our-features-wrapper {
	padding: 80px 30px 72px;
}
.client-active .owl-nav div {
	left: 0;
}
.client-active .owl-nav div.owl-next {
	left: auto;
	right: 0;
}
.pricing-bg {
	padding-left: 0;
}
.download-img {
	margin-left: 0;
}
.download-img img {
	width: 100%;
}
.download-wrapper {
	padding-left: 0;
	margin-top: 0;
}
.area-title {
	padding-left: 0;
	padding-right: 0;
}
.download-area {
	padding-bottom: 90px;
}
.header-area {
	padding: 25px 15px;
	padding-bottom: 10px;
}
.slider-content {
	margin-top: 50px;
}
.slider-content h1 {
	font-size: 50px;
}
.slider-text h1 {
	font-size: 50px;
}
.slider-content p {
	margin-right: 0;
}
.slider-height {
	min-height: 600px;
}
.gallery-active .owl-nav div {
	left: 0;
}
.gallery-active .owl-nav div.owl-next {
	left: auto;
	right: 0;
}
.we-are {
	padding-bottom: 50px;
}
.features-02 .features-02-wrapper {
	margin-left: 0;
	margin-right: 0;
}
.features-3-img img {
	width: 100%;
}
.breadcrumb-area {
	padding-top: 200px;
	padding-bottom: 120px;
}
.testimonial-2-wrapper {
	padding: 32px 15px 46px 15px;
}
.testimonial-2-img {
	margin-top: -46px;
}
.footer-wrapper{
	padding-left: 0;
	padding-right: 0;
}
.features-1-content p {
	padding-right: 0;
}
.cta-text {
	padding-left: 0;
	padding-right: 0;
}
.features-bg {
	padding: 70px 40px 42px 40px;
}
.portfolio-menu {
	margin-left: 0;
	margin-top: 0;
}
.project-details-bg {
	padding-left: 0;
	padding-right: 0;
}
.pricing-2-list {
	padding-left: 0;
}
.single-pricing.active {
	margin-top: 0;
}
.pricing-1 .area-title {
	margin-bottom: 70px;
}
.testimonial-active .owl-nav div {
	display: none;
}
.single-blog .blog-text {
	padding: 60px 15px 50px 15px;
}
.choose-img img {
	width: 100%;
}
.features-2-img img {
	width: 100%;
}
.features-03-wrapper {
	padding-left: 0;
}
.blog-3-area {
	padding-top: 115px;
}
.blog-text > h4 {
	font-size: 18px;
}
.contact-address-title {
	padding-right: 0;
}
.header-3 .header-right::before {
	display: none;
}
.blog-2 {
	padding-top: 110px;
}



}


/* small mobile :320px. */
@media (max-width: 767px) {
.features-content h3 {
	font-size: 20px;
}
.features-icon {
	margin-right: 15px;
}
.single-features {
	padding-right: 0;
}
.contact-address-title {
	padding-right: 0;
}
.single-1-team {
	margin-right: 0;
}
.section-1-title h1 {
	font-size: 38px;
}
.features-1-img img {
	width: 100%;
}
.features-1-img {
	margin-left: 0;
	margin-top: 0;
}
.section-title h1 {
	font-size: 38px;
}
.services-text p {
	padding: 0;
}
.section-button {
	margin-top: 25px;
}
.team-active .owl-nav div {
	left: 0;
}
.faq-img {
	margin-left: 0;
}
.faq-img img {
	width: 100%;
}
.testimonial-img {
	margin-left: 0;
}
.testimonial-img img {
	width: 100%;
}
.cta-img {
	margin-bottom: 0;
}
.newsletters-wrapper {
	padding-left: 15px;
	padding-right: 15px;
}
.team-active .owl-nav div {
	display: none;
}
.blog-meta span > a {
	padding: 4px 9px;
}
.testimonial-text p {
	margin-right: 0;
}
.cta-text h1 {
	font-size: 38px;
}
.cta-img img {
	width: 100%;
}
.blog-meta span {
	margin-right: 7px;
}
.newsletters-text h1 {
	font-size: 37px;
	padding: 0;
}
.newsletter-form form button.btn {
	position: inherit;
	right: 0;
	top: 15px;
	padding: 17px 42px;
}
.newsletter-form form input {
	border: 0;
	height: 70px;
	padding: 0 67px;
}
.footer-wrapper {
	padding-left: 0;
	padding-right: 0;
}
.counter-text h1 {
	font-size: 60px;
}
.faq-box .btn-link {
	padding: 20px 20px;
}
.testimonial-active .owl-nav div {
	display: none;
}
.faq-box .btn-link::before {
	right: 15px;
}
.faq-box .card-body {
	padding: 0 20px;
}
.about-wrapper {
	padding-right: 0;
}
.about-img {
	margin-left: 0;
}
.about-img img{width:100%;}
.our-features-wrapper {
	padding: 80px 30px 72px;
}
.client-active .owl-nav div {
	left: 0;
}
.client-active .owl-nav div.owl-next {
	left: auto;
	right: 0;
}
.pricing-bg {
	padding-left: 0;
}
.download-img {
	margin-left: 0;
}
.download-img img {
	width: 100%;
}
.download-wrapper {
	padding-left: 0;
	margin-top: 0;
}
.area-title {
	padding-left: 0;
	padding-right: 0;
}
.download-area {
	padding-bottom: 90px;
}
.area-title h1 {
	font-size: 38px;
}
.our-services-wrapper {
	padding: 55px 20px 60px 20px;
}
.inner-client {
	padding: 54px 20px 58px 20px;
	margin-right: 0;
	margin-left: 0;
}
.client-active .owl-nav div {
	display: none;
}
.pricing-tab li a {
	padding: 16px 35px;
}
.download-app a.btn.active {
	margin-left: 0;
	margin-top: 15px;
}
.newsletter-2-form form button.btn {
	position: inherit;
	right: 0;
	top: 15px;
	padding: 14px 44px;
}
.header-area {
	padding: 25px 15px;
	padding-bottom: 10px;
}
.slider-content {
	margin-top: 40px;
}
.slider-height {
	min-height: 600px;
}
.slider-content h1 {
	font-size: 36px;
}
.slider-text h1 {
	font-size: 32px;
}
.slider-content p {
	margin-right: 0;
}
.btn-white {
	margin-left: 0;
	margin-top: 15px;
}
.btn-gray {
	margin-left: 0;
	margin-top: 15px;
}
.we-are {
	padding-bottom: 50px;
}
.features-02 .features-02-wrapper {
	margin-left: 0;
	margin-right: 0;
}
.features-3-img img {
	width: 100%;
}
.breadcrumb-area {
	padding-top: 200px;
	padding-bottom: 120px;
}
.testimonial-2-wrapper {
	padding: 32px 15px 46px 15px;
}
.gallery-active .owl-nav div {
	display: none;
}
.breadcrumb-text > h1 {
	font-size: 38px;
}
.breadcrumb-menu li a {
	font-size: 20px;
}
.breadcrumb-menu span {
	font-size: 20px;
}
.testimonial-2-img {
	margin-top: -44px;
}
.testimonial-2-text h4 {
	font-size: 15px;
}
.features-1-content p {
	padding-right: 0;
}
.cta-text {
	padding-left: 0;
	padding-right: 0;
}
.features-bg {
	padding: 70px 40px 42px 40px;
}
.services-2-details-img img {
	width: 100%;
}
.portfolio-menu {
	margin-left: 0;
	margin-top: 0;
}
.portfolio-menu button::before {
	display: none;
}
.project-content {
	padding: 21px 25px 26px;
}
.project-details-bg {
	padding-left: 0;
	padding-right: 0;
}
.pro-details-info-list {
	margin-right: 60px;
	margin-bottom: 10px;
}
.project-details-content h1 {
	font-size: 38px;
}
.features-bg-2 {
	padding-left: 15px;
	padding-right: 15px;
}
.pricing-1 .area-title {
	margin-bottom: 70px;
}
.single-pricing {
	padding: 50px 15px 56px 15px;
}
.pricing-2-list {
	padding-left: 0;
}
.single-pricing.active {
	margin-top: 0;
}
.single-pricing.active::before {
	display: none;
}
.single-blog .blog-text {
	padding: 60px 15px 50px 15px;
}
.single-blog .blog-meta {
	padding: 20px 10px 20px 10px;
}
.choose-img img {
	width: 100%;
}
.features-2-img img {
	width: 100%;
}
.features-03-wrapper {
	padding-left: 0;
}
.blog-3-area {
	padding-top: 115px;
}
.features-03-wrapper .features-list li {
	float: none;
	width: auto;
}
.blog-standard .blog-text > h4 {
	font-size: 22px;
}
.blog-details-wrapper blockquote {
	padding: 50px 30px 50px 30px;
}
.blog-details-wrapper blockquote::before {
	position: static;
}
.author {
	padding: 0 30px 44px 30px;
}
.comments-avatar {
	float: none;
}
.conatct-post-form {
	padding: 20px;
}
.blog-details-wrapper blockquote p {
	font-size: 22px;
}
.comments-avatar img {
	margin-right: 0;
	margin-bottom: 10px;
}
.pro-filter {
	float: none;
}
.plus-minus {
	margin-bottom: 15px;
}
.product-social a {
	margin-right: 3px;
}
.review-tab .nav-item {
	margin-right: 30px;
}
.product-showing p {
	margin: 0;
	margin-top: 0;
}
.slider-1-height {
	min-height: 680px;
}
.header-3 .header-right::before {
	display: none;
}
.testimonial-nav {
	width: 270px;
}
.blog-2 {
	padding-top: 110px;
}
.project-active .owl-nav div {
	display: none;
}
.copyright p {
	text-align: center;
}
.footer-bottom-link{
	text-align: center;
}

}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
.container-box {
	max-width: 550px;
}
.btn-white {
	margin-left: 10px;
	margin-top: 0;
}
.btn-gray {
	margin-left: 10px;
	margin-top: 0;
}

}
.bold_h2{
	font-size: 55px;
	font-weight: 500;
	line-height: 1.1;
	margin-bottom: 0;
	letter-spacing: -3px;
}

.boldwhite_h2{
	font-size: 55px;
	font-weight: 500;
	line-height: 1.1;
	margin-bottom: 20px;
	letter-spacing: -3px;
	color: #fff;
}
.a_contact{
	font-size: 16px;
	line-height: 28px;
	margin-bottom: 0;
	color: #9d98bf;
}


