/*

  Theme Name: Traders - Digital Marketing & Agency HTML5 Template

  Description: Digital Marketing & Agency HTML5 Template.

  Version: 1.0

*/



/* CSS Index

-----------------------------------

1. Theme default css

2. header

3. slider

4. features-area

5. client-area

6. services-area

7. counter-area

8. project-area

9. team-area

10. faq-area

11. testimonial-area

12. cta-area

13. blog-area

14. newsletters-area

15. footer

16. about-area

17. study-area

18. pricing-area

19. download-area

20. choose-area

21. breadcrumb-area

22. services-details

23. blog-details

24. map

25. contact

26. shop-area

*/







/* 1. Theme default css */

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900|Rubik:300,400,500,700,900');

 body {

	font-family: 'Poppins', sans-serif;

	font-weight: normal;

	font-style: normal;

}

.img {

	max-width: 100%;

	transition: all 0.3s ease-out 0s;

}

.f-left {

	float: left

}

.f-right {

	float: right

}

.fix {

	overflow: hidden

}

a,

.button {

	-webkit-transition: all 0.3s ease-out 0s;

	-moz-transition: all 0.3s ease-out 0s;

	-ms-transition: all 0.3s ease-out 0s;

	-o-transition: all 0.3s ease-out 0s;

	transition: all 0.3s ease-out 0s;

}

a:focus,

.button:focus {

	text-decoration: none;

	outline: none;

}

a:focus,

a:hover,

.portfolio-cat a:hover,

.footer -menu li a:hover {

	color: #2B96CC;

	text-decoration: none;

}

a,

button {

	color: #1696e7;

	outline: medium none;

}

button:focus,input:focus,input:focus,textarea,textarea:focus{outline: 0}

.uppercase {

	text-transform: uppercase;

}

.capitalize {

	text-transform: capitalize;

}

h1,

h2,

h3,

h4,

h5,

h6 {

 font-family: 'Rubik', sans-serif;

	font-weight: normal;

	color: #272063;

	margin-top: 0px;

	font-style: normal;

	font-weight: 500;

	text-transform: normal;

	line-height: 1.4;

}

h1 a,

h2 a,

h3 a,

h4 a,

h5 a,

h6 a {

	color: inherit;

}

h1 {

	font-size: 40px;

	font-weight: 500;

}

h2 {

	font-size: 35px;

}

h3 {

	font-size: 28px;

}

h4 {

	font-size: 22px;

}

h5 {

	font-size: 18px;

}

h6 {

	font-size: 16px;

}

ul {

	margin: 0px;

	padding: 0px;

}

li {

	list-style: none

}

p {

	font-size: 14px;

	font-weight: normal;

	line-height: 30px;

	color: #9d98bf;

	margin-bottom: 15px;

}

hr {

	border-bottom: 1px solid #eceff8;

	border-top: 0 none;

	margin: 30px 0;

	padding: 0;

}

label {

	color: #7e7e7e;

	cursor: pointer;

	font-size: 14px;

	font-weight: 400;

}

*::-moz-selection {

	background: #d6b161;

	color: #fff;

	text-shadow: none;

}

::-moz-selection {

	background: #444;

	color: #fff;

	text-shadow: none;

}

::selection {

	background: #444;

	color: #fff;

	text-shadow: none;

}

*::-moz-placeholder {

	color: #555555;

	font-size: 14px;

	opacity: 1;

}

*::placeholder {

	color: #555555;

	font-size: 14px;

	opacity: 1;

}

.theme-overlay {

	position: relative

}

.theme-overlay::before {

	background: #1696e7 none repeat scroll 0 0;

	content: "";

	height: 100%;

	left: 0;

	opacity: 0.6;

	position: absolute;

	top: 0;

	width: 100%;

}

.separator {

	border-top: 1px solid #f2f2f2

}

/* button style */



.btn {

	-moz-user-select: none;

	background: #ff5b67;

	border: medium none;

	color: #fff;

	display: inline-block;

	font-size: 13px;

	font-weight: 500;

	margin-bottom: 0;

	padding: 18px 44px;

	text-align: center;

	text-transform: uppercase;

	touch-action: manipulation;

	transition: all 0.3s ease 0s;

	vertical-align: middle;

	white-space: nowrap;

	font-family: 'Rubik', sans-serif;

	letter-spacing: 2px;

	border-radius: 30px;

}

.btn:hover{background:#272063;color:#fff;}

.breadcrumb > .active {

	color: #888;

}



/* scrollUp */

#scrollUp {

	background: #ff5b67;

	height: 35px;

	width: 35px;

	right: 50px;

	bottom: 77px;

	color: #fff;

	font-size: 20px;

	text-align: center;

	border-radius: 50%;

	font-size: 20px;

	line-height: 34px;

}

#scrollUp:hover {

	background: #272063;

}

/* 2 header */

.header-transparent {

	position: absolute;

	top: 0;

	left: 0;

	right: 0;

	z-index: 9;

	transition: .4s;

}

 .sticky {

	left: 0;

	margin: auto;

	position: fixed;

	top: 0;

	width: 100%;

	box-shadow: 0 0 60px 0 rgba(0, 0, 0, .07);

	z-index: 9999;

	-webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;

	animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;

	-webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);

	background: #fff;

}

.header-3.sticky{padding-top:25px;}

.header-area {

	padding: 25px 165px;

}

.menu-bar {

	width: 30px;

	cursor: pointer;

	position: relative;

	margin-right: 30px;

	margin-left: 30px;

	margin-top: 20px;

}

.menu-bar span {

	display: block;

	margin: 5px 0;

	transition: .3s;

	margin-left: auto;

	width: 100%;

	background: #ff5b67;

	height: 2px;

}

.header-social {

	margin-top: 21px;

}

.header-social a {

	font-size: 16px;

	color: #9d98c6;

	margin-left: 10px;

	transition: .3s;

}

.header-social a:hover{color:#ff5b67;}

.header-social a:last-child{margin-left: 0;}

.header-button {

	margin-top: 8px;

}

.header-button a.btn {

	color: #ff5b67;

	background: none;

	border: 3px solid #e5eafd;

	padding: 16px 37px;

	transition: .3s;

}

.header-button a.btn:hover{background:#ff5b67;color:#fff;border-color:#ff5b67;}



/* header-top-area */

.header-top-area {

	background: #f4f4fe;

	padding: 10px 0;

}

.header-top-area .header-social {

	margin-top: 4px;

}

.heder-top-text p {

	margin-bottom: 0;

}

.header-info{position: relative;margin-right: 30px;padding-right: 30px;}

.header-info::before {

	position: absolute;

	content: "";

	background: #bebae5;

	height: 20px;

	width: 2px;

	right: 0;

	top: 6px;

}

.header-info p i {

	font-size: 14px;

	position: relative;

	margin-right: 7px;

}

.header-info p {

	display: inline-block;

	color: #9d98bf;

	margin-bottom: 0;

}

.header-middle-area {

	padding: 20px 0;

}

.header-cta-text span {

	font-weight: 500;

	color: #9d98bf;

	font-size: 13px;

	text-transform: uppercase;

	letter-spacing: 2px;

	font-family: 'Rubik', sans-serif;

}

.header-cta-text span i {

	margin-right: 6px;

	color: #ff5b67;

	font-size: 14px;

}

.header-cta-text p {

	color: #272063;

	font-weight: 400;

	font-size: 14px;

	margin-bottom: 0;

	letter-spacing: -.2px;

	font-family: 'Rubik', sans-serif;

}

.header-border{border-top:1px solid #f0f0fc;}

.header-border .menu-bar {

	margin-right: 0;

	margin-left: 0;

	margin-top: 27px;

}

.header-3 {

	padding-top: 46px;

}

.header-3 .header-right{position: relative;}

.header-3 .header-right::before {

	position: absolute;

	content: "";

	background: #bebae5;

	height: 20px;

	width: 2px;

	left: -32px;

	top: 25px;

}

.header-3 .header-social a {

	font-size: 16px;

	color: #a39cd6;

	margin-left: 0;

	transition: .3s;

	height: 40px;

	width: 40px;

	display: inline-block;

	background: #f1f4ff;

	text-align: center;

	border-radius: 50%;

	line-height: 40px;

	margin: 0 5px;

}

.header-3 .header-social a:hover{background: #ff5b67;color:#fff;}

.header-3 .header-social a:last-child{padding-right: 0;}

.header-3 .header-social a:first-child{padding-left: 0;}

.header-3 .header-social {

	margin-top: 16px;

}

.header-3 .menu-bar {

	margin-right: 0;

	margin-left: 23px;

	margin-top: 20px;

}

.header-3

.header-3 .menu-bar {

	width: 30px;

	cursor: pointer;

	position: relative;

	margin-right: 0;

	margin-left: 23px;

	margin-top: 20px;

}

.header-3 .menu-bar span {

	background: #272063;

}

/* extra info */

.extra-info {

	background: #152136;

	height: 100%;

	padding: 30px;

	position: fixed;

	right: 0;

	top: 0;

	transition: all 0.7s ease 0s;

	width: 340px;

	z-index: 9999;

	transform: translateX(100%);

	padding-bottom: 40px;

}

.extra-info.info-open {

  transform: translateX(0);

}

.extra-info-left{right:inherit;left: 0;transform: translateX(-100%);}

.extra-info.extra-info-left.info-open{transform: translateX(0);}

.close-icon {

  margin-top: -16px;

  text-align: right;

}

.close-icon > button {

  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;

  border: 0 none;

  color: #ff5b67;

  cursor: pointer;

  font-size: 20px;

  padding: 0;

}

.extra-info > p {

  color: #ddd;

  font-size: 15px;

  line-height: 28px;

}

.extra-info.extra-info-left.info-open {

	transform: translateX(0);

}

.instagram img {

  width: 100%;

}

.social-icon-right > a {

	color: #fff;

	display: inline-block;

	margin-right: 20px;

	text-align: center;

}

.social-icon-right > a:hover {

	color: #ff5b67;

}

.instagram {

  overflow: hidden;

  margin: 0 -4px;

}

.instagram > a {

	float: left;

	width: 33.33%;

	padding: 0 4px;

	margin-bottom: 7px;

}

.side-info {

	border-top: 1px solid #344159;

	padding-top: 25px;

}

.contact-list h4 {

	color: #fff;

	font-weight: 500;

	font-size: 18px;

}

.contact-list p {

	color: #fff;

	opacity: .5;

	margin: 0;

	margin-bottom: 5px;

	line-height: 28px;

}

.side-menu ul li:hover a {

  color: #ff5b67; }

.menu-border {

	border-bottom: 1px solid #e9e9e9;

}

/* menu */

.main-menu nav > ul > li {

	display: inline-block;

	position: relative;

	margin: 0 12px;

}

.main-menu nav > ul > li:first-child{margin-left:0;}

.main-menu nav > ul > li > a {

	color: #9d98c6;

	display: block;

	font-size: 20px;

	font-weight: 500;

	padding: 28px 0;

	transition: all 0.3s ease 0s;

	background: transparent;

	line-height: 1;

	position: relative;

	text-transform: capitalize;

	font-family: 'Rubik', sans-serif;

}

.main-menu nav > ul  > li:hover > a,.main-menu nav > ul  > li.active > a{color:#ff5b67;}

.main-menu nav > ul > li > a::before {

	background: #ff5b67;

	content: "";

	height: 2px;

	position: absolute;

	bottom: 0;

	width: 0;

	transition: .5s;

	right: 0;

	left: 0;

	margin: auto;

}

.main-menu > nav > ul > li:hover > a::before, .main-menu > nav > ul > li.active > a::before {

	width: 20px;

}

.header-border .main-menu nav > ul > li > a::before {

display: none;

}

.header-3 .main-menu nav > ul > li > a::before {

display: none;

}

/* sub-menu */

.main-menu nav > ul  > li .sub-menu {

  background: #ffffff none repeat scroll 0 0;

  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);

  left: 0;

  opacity: 0;

  position: absolute;

  top: 120%;

  transition: all 0.3s ease 0s;

  visibility: hidden;

  width: 220px;

  z-index: 9;

	border-bottom:3px solid #ff5b67;

	text-align: left;

}

.main-menu nav > ul  > li:hover .sub-menu{opacity:1;top:100%;visibility:visible}

.main-menu nav > ul  > li .sub-menu li{display:block}

.main-menu nav > ul > li .sub-menu li a {

	color: #666666;

	font-size: 14px;

	font-weight: 400;

	margin: 0;

	padding: 13px 20px;

	text-transform: capitalize;

	display: block;

}

.main-menu nav > ul  > li:hover > .sub-menu li > a:hover{color:#fff;background:#ff5b67;}

.header2 .main-menu nav > ul  > li:hover > .sub-menu li > a:hover{color:#fff;background:#b620b4;}

.header2 .main-menu nav > ul  > li .sub-menu {

  border-bottom:3px solid #b620b4;

}



/* 3. slider */

.slider-area {

  position: relative;

}

.slider-area .banner-img {

	position: absolute;

	content: '';

	right: 0;

	top: 0;

	height: 100%;

	z-index: -1;

}

.slider-height {

	min-height: 950px;

}

.slider-1-height {

	min-height: 1130px;

}

.single-slider {

	background-size: cover;

	background-position: center center;

}

.slider-content h1 {

	font-size: 85px;

	font-weight: 300;

	margin-bottom: 20px;

	line-height: 1;

	color: #675eac;

	text-transform: capitalize;

	letter-spacing: -.3px;

}

.slider-content h1 strong {

	color: #272063;

	font-weight: 500;

}

.slider-content h1 strong span {

	color: #ff5b67;

}

.slider-content p {

	font-size: 16px;

	line-height: 30px;

	margin-bottom: 0;

	color: #837bb6;

	margin-right: 12%;

}

.slider-button {

	margin-top: 33px;

}

.btn-white {

	background: #fff;

	color: #272063;

	margin-left: 10px;

}

.btn-gray {

	background: #f4f4fe;

	color: #272063;

	margin-left: 10px;

}

.slider-2-img {

	margin-left: -182px;

}

.slider-2-content{

	padding-left: 30px;

}

.s-01 {

	bottom: 8%;

	right: 7%;

}

.slider-text h1 {

	font-size: 85px;

	font-weight: 300;

	margin-bottom: 35px;

	line-height: 1;

	color: #272063;

	text-transform: capitalize;

	letter-spacing: -.3px;

}

.slider-text h1 strong{color:#ff5b67;font-weight: 500;}

.slider-text p {

	color: #272063;

	font-weight: 500;

	font-size: 22px;

	margin-bottom: 47px;

}

/* 4. features-area */

.section-1-title > span {

	position: relative;

	font-weight: 700;

	font-family: 'Rubik', sans-serif;

	font-size: 14px;

	color: #ff5b67;

	letter-spacing: 2px;

	text-transform: uppercase;

	margin-bottom: 23px;

	display: inline-block;

}

.section-1-title > span::before {

	position: absolute;

	content: '';

	background: #b9b3ea;

	width: 60px;

	height: 2px;

	bottom: 6px;

	right: -79px;

}

.section-1-title h1 {

	font-size: 55px;

	color: #675eac;

	font-weight: 300;

	line-height: 1.1;

	margin-bottom: 0;

	letter-spacing: -3px;

}

.section-1-title h1 strong{color:#272063;font-weight: 500;}

.section-1-title h1 strong span{color:#ff5b67;}

.section-1-title p {

	margin-bottom: 0;

	margin-top: 46px;

}

.section-title h1 {

	font-size: 55px;

	color: #675eac;

	font-weight: 300;

	line-height: 1.1;

	margin-bottom: 0;

	letter-spacing: -3px;

	position: relative;

	padding-bottom: 40px;

}

.section-title h1 strong{color:#272063;font-weight: 500;}

.section-title h1 strong span{color:#ff5b67;}

.section-title h1::before {

	background: #c2cfff;

	width: 85px;

	height: 3px;

	padding: 0;

	position: absolute;

	content: "";

	bottom: 0;

	left: 0;

}

.section-title p {

	margin-bottom: 0;

	margin-top: 35px;

}

.features-02-wrapper .section-title p {

	margin-top: 38px;

}

.white-title > span {

	font-size: 14px;

	color: #fff;

	font-family: 'Rubik', sans-serif;

	font-weight: 500;

	text-transform: uppercase;

	letter-spacing: 2px;

	display: inline-block;

	margin-bottom: 23px;

}

.white-title h1 {

	color: #fff;

	padding-bottom: 0;

}

.white-title h1 strong{color:#fff;}

.white-title h1 strong span{color:#fff;}

.white-title h1::before{display: none;}

.section-button a.btn {

	color: #252525;

	background: #e5eafd;

}

.section-button a.btn:hover{background: #ff5b67;color:#fff;}

.section-title-center h1{position: relative;}

.section-title-center h1::before {

	background: #c2cfff;

	width: 85px;

	height: 3px;

	padding: 0;

	position: absolute;

	content: "";

	bottom: 0;

	left: 0;

	right: 0;

	margin: auto;

}

.features-content {

	overflow: hidden;

}

.features-icon {

	float: left;

	margin-right: 30px;

}

.features-content h3 {

	font-weight: 500;

	margin-bottom: 0;

	text-transform: capitalize;

	font-size: 24px;

	line-height: 1.2;

	margin-top:10px;

}

.features-text {

	margin-top: 18px;

}

.features-text p{margin-bottom: 0;}

.our-features-area {

	background-size: cover;

	background-position: center center;

}

.our-features-wrapper {

	background: #fff;

	padding: 80px 50px 72px;

	border: 2px solid transparent;

	transition: .3s;

	border-radius: 15px;

}

.our-features-wrapper:hover{border: 2px solid #ff5b67;}



/* features-2 */

.features-02-area {

	background-size: cover;

	background-position: center center;

}

.features-list {

	margin-bottom: 27px;

	margin-top: 19px;

}

.features-list li {

	color: #938ebb;

	font-size: 14px;

	padding-bottom: 9px;

}

.features-list li:last-child{margin-bottom: 0;}

.features-list li i {

	font-size: 16px;

	position: relative;

	top: 4px;

	margin-right: 4px;

}

.features-1-img {

	margin-left: -83px;

	margin-top: 20px;

}

.features-02 .features-02-wrapper {

	margin-left: 70px;

	margin-right: 45px;

}

.features-02 .features-button {

	margin-top: 44px;

}

.features-1-content p {

	margin-bottom: 0;

	padding-right: 15px;

}



/* features-3 */

.features-03-wrapper {

	padding-left: 70px;

}

.features-03-wrapper .section-title h1 {

	color: #272063;

	font-weight: 500;

}

.features-03-wrapper .features-list {

	overflow: hidden;

	margin-top:25px;

}

.features-03-wrapper .features-list li {

	float: left;

	width: 50%;

}

.features-03-wrapper .features-button a.btn {

	background: #f4f4fe;

	color: #272063;

}

.features-03-wrapper .features-button a.btn:hover{background: #272063;color:#fff;}



/* features-4-style */

.single-1-features{border:0;}

.single-1-features:hover{border:0;}

.features-bg {

	box-shadow: 0px 16px 32px 0px rgba(236, 236, 236, 0.56);

	padding: 70px 100px 42px 100px;

	background: #fff;

	margin-top: -130px;

}



/* we-area */

.we-area-bg {

	margin-top: 36px;

}

.we-area-content h3 {

	font-size: 24px;

	font-weight: 500;

	color: #4b4293;

	margin-bottom: 0;

	line-height: 1.5;

}

.we-area-content h1 {

	position: absolute;

	top: 50%;

	transform: translatey(-50%);

	color: #bdb8df;

	font-weight: 500;

	font-size: 100px;

	line-height: 1;

	margin-bottom: 0;

	left: 0;

	right: 0;

	text-align: center;

	z-index: -1;

	opacity: .2;

}

.we-are .section-1-title p {

	margin-top: 30px;

}



/* 5. client-area */

.client-wrapper {

	padding: 23px 40px;

	border-radius: 13px;

	background-color: white;

	box-shadow: 0px 16px 32px 0px rgba(229, 229, 229, 0.67);

}

.client-icon {

	float: left;

	margin-right: 15px;

}

.client-text h4{

	overflow: hidden;

	color: #9d98bf;

	font-weight: 500;

	margin-bottom: 0;

	font-size: 16px;

}



/* 6. services-area */

.services-icon {

	margin-bottom: 41px;

}

.services-text h3 {

	font-size: 24px;

	font-weight: 500;

	text-transform: capitalize;

	margin-bottom: 18px;

}

.services-text p {

	margin-bottom: 0;

	padding: 0 43px;

}

.services-text a {

	font-size: 13px;

	color: #272063;

	font-weight: 500;

	text-transform: uppercase;

	letter-spacing: 2px;

	font-family: 'Rubik', sans-serif;

	display: inline-block;

	margin-top: 20px;

	transition: .3s;

}

.services-text a:hover{color:#ff5b67;}



/* services-2 */

.our-services-area {

	background-size: cover;

	background-position: center center;

}

.our-services-wrapper {

	background: #2f276f;

	padding: 55px 40px 60px 40px;

}

.our-services-icon {

	float: left;

	margin-right: 20px;

}

.our-services-icon i {

	font-size: 24px;

	color: #fff;

	transition: .3s;

}

.our-services-wrapper:hover .our-services-icon i{color:#ff5b67;}

.our-services-text h4 {

	font-size: 20px;

	color: #fff;

	margin-bottom: 0;

	font-weight: 500;

	transition: .3s;

}

.our-services-wrapper:hover .our-services-text h4{color:#ff5b67;}

.our-services-content p {

	color: #b6aff1;

	margin-bottom: 0;

	margin-top: 16px;

	transition: .3s;

}

.our-services-content > a {

	color: #fff;

	text-transform: capitalize;

	font-size: 13px;

	font-weight: 500;

	font-family: 'Rubik', sans-serif;

	display: inline-block;

	margin-top: 22px;

	position: relative;

	transition: .3s;

	padding: 7px 12px;

}

.our-services-content > a::before {

	position: absolute;

	background: #221c58;

	content: "";

	height: 30px;

	width: 30px;

	top: 0;

	left: 0;

	height: 100%;

	z-index: 1;

	transition: .3s;

}

.our-services-content > a:hover::before {

	background: #ff5b67;

	width: 100%;

}

.our-services-content a span {

	position: relative;

	z-index: 999;

}

/* .services-3-style */

.services-1-wrapper {

	background: #fbf9fe;

	padding: 50px 20px 42px;

}

.services-1-icon {

	margin-bottom: 40px;

}

.services-1-icon i {

	color: #ff5b67;

	font-size: 30px;

	height: 80px;

	width: 80px;

	border-radius: 50%;

	box-shadow: 0px 10px 30px 0px rgba(223, 211, 248, 0.4);

	line-height: 80px;

	background: #ffff;

	text-align: center;

}

.services-1-text h3 {

	font-size: 24px;

	font-weight: 500;

	margin-bottom: 13px;

}

.services-1-text p {

	margin-bottom: 0;

}

/* 7. counter-area */

.counter-area {

	background-size: cover;

	background-position: center center;

}

.counter-text span {

	color: #fff;

	font-size: 24px;

	font-weight: 400;

	line-height: 1.4;

	margin-bottom: 25px;

	display: inline-block;

}

.counter-text h1 {

	font-size: 80px;

	color: #fff;

	font-weight: 500;

	line-height: 1;

	position: relative;

	margin-bottom: 0;

	padding-bottom: 41px;

}

.counter-text h1::after {

	background: #fff;

	height: 3px;

	width: 22px;

	content: "";

	position: absolute;

	left: 0;

	bottom: 0;

}

.counter-text h1::before {

	background: #fff;

	height: 3px;

	width: 35px;

	content: "";

	position: absolute;

	left: 33px;

	bottom: 0;

}

.single-counter {

	box-shadow: 0px 16px 32px 0px rgba(199, 199, 199, 0.3);

	padding: 60px 10px 54px;

	transition: .3s;

	border:1px solid transparent;

}

.single-counter:hover{border-color: #ff5b67;transform:translateY(-5px)}

.counter-1-icon {

	position: relative;

	margin-bottom: 32px;

	display: inline-block;

}

.counter-1-icon > i{

	font-size: 30px;

	height: 80px;

	width: 80px;

	background: #fcebd2;

	text-align: center;

	margin: auto;

	border-radius: 50%;

	line-height: 80px;

	color: #272063;

}

.c-tag {

	position: absolute;

	top: -6px;

	left: -3px;

}

.c-tag > i {

	height: 30px;

	width: 30px;

	background: #ff911d;

	color: #fff;

	font-size: 14px;

	border-radius: 50%;

	line-height: 30px;

}

.counter-content h1 {

	font-size: 48px;

	line-height: 1;

	margin-bottom: 5px;

}

.counter-content span {

	color: #9d98bf;

	font-size: 14px;

	font-weight: 400;

	font-family: 'Rubik', sans-serif;

}



/* 8. project-area */

.project-area {

	background-position: bottom;

	background-repeat: no-repeat;

}

.project-active .owl-nav div {

	background: #e5eafd;

	height: 40px;

	width: 50px;

	right: 15px;

	content: "";

	z-index: -1;

	top: -118px;

	transition: .3s;

	position: absolute;

	text-align: center;

	line-height: 43px;

}

.project-active .owl-nav div.owl-prev {

	right: 80px;

}

.project-active .owl-nav div:hover{background: #ff5b67;color:#fff;}



/* project-2-style */

.recent-project-img{position: relative;}

.recent-project-img::before {

	position: absolute;

	content: "";

	top: 30px;

	bottom: 30px;

	left: 30px;

	right: 30px;

	background: #ff5b67;

	opacity: 0;

	transition: .3s;

}

.recent-project-wrapper:hover .recent-project-img::before{opacity: 1;}

.recent-project-img > a img {

	width: 100%;

}

.recent-project-text {

	position: absolute;

	top: 45%;

	left: 0;

	right: 0;

	transform: translatey(-50%);

	transition: .3s;

	opacity: 0;

}

.recent-project-wrapper:hover .recent-project-text{opacity: 1;top:50%;}

.recent-project-text h3 {

	font-size: 24px;

	letter-spacing: -.3px;

	margin-bottom: 3px;

}

.recent-project-text h3 > a{color:#fff;}

.recent-project-text span {

	font-size: 14px;

	text-transform: uppercase;

	letter-spacing: 2px;

	color: #fff;

}



/* gallery */

.gallery-active .owl-nav div {

	background: #f4f7ff;

	font-size: 20px;

	left: -95px;

	opacity: 0;

	position: absolute;

	text-align: center;

	top: 50%;

	transition: all 0.3s ease 0s;

	visibility: hidden;

	color: #938ebb;

	font-weight: 400;

	height: 50px;

	width: 50px;

	line-height: 50px;

	border-radius: 50%;

	transform: translateY(-50%);

}

.gallery-active .owl-nav div.owl-next {

	left: auto;

	right:-95px;

}

.gallery-active:hover .owl-nav div {

	opacity: 1;

	visibility: visible;

}

.gallery-active .owl-nav div:hover {

	background:#ff5b67;

	color:#fff;

}



/* project-3 */

.project-img a img {width:100%;}

.project-content {

	box-shadow: 0px 16px 32px 0px rgba(236, 236, 236, 0.56);

	padding: 21px 40px 26px;

}

.project-content h4 {

	font-size: 24px;

	text-transform: capitalize;

	font-weight: 500;

	margin-bottom: 0;

}

.project-content h4 > a{color:#272063;}

.project-content span {

	color: #9d98bf;

	font-size: 14px;

	font-weight: 400;

}

.portfolio-button a.btn {

	background: none;

	border: 2px solid #ff5b67;

	color: #ff5b67;

}

.portfolio-button a.btn:hover {

	border: 2px solid #ff5b67;

	color: #fff;

	background: #ff5b67;

}

.portfolio-menu {

	border-bottom: 2px solid #e3e8ff;

	margin-left: 135px;

	padding-bottom: 16px;

}

.portfolio-menu button {

	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;

	border: medium none;

	color: #272063;

	font-size: 14px;

	line-height: 1;

	transition: all 0.3s ease 0s;

	cursor: pointer;

	text-transform: uppercase;

	padding: 0 9px;

	font-weight: 500;

	letter-spacing: -.3px;

	position: relative;

	font-family: 'Rubik', sans-serif;

}

.portfolio-menu button::before {

	bottom: -22px;

	background: #ff5b67;

	content: "";

	position: absolute;

	z-index: 999;

	left: 0;

	opacity: 0;

	height: 2px;

	width: 100%;

	right: 0;

}

.portfolio-menu button.active::before {

	opacity: 1;

}

.portfolio-menu button:focus {

    outline: 0 none;

}

.portfolio-menu button:hover {

    color: #ff5b67;

}

.portfolio-menu button.active {

    color: #ff5b67;

}

.custom {

    margin: 0 -15px;

}

/* project-details */

.project-details-img img{width:100%;}

.project-details-bg {

	padding-left: 100px;

	padding-right: 100px;

}

.project-details-info {

	overflow: hidden;

	margin-top: 33px;

	margin-bottom: 48px;

}

.pro-details-info-list {

	float: left;

	margin-right: 75px;

}

.pro-details-info-list:last-child{margin-right:0;}

.pro-details-icon {

	float: left;

	margin-right: 15px;

}

.pro-details-icon i {

	font-size: 24px;

	color: #ff5b67;

	margin-top: 11px;

}

.project-details-text {

	overflow: hidden;

}

.project-details-text span {

	font-size: 14px;

	color: #9d98bf;

	font-weight: 400;

	font-family: 'Rubik', sans-serif;

}

.project-details-text h5 {

	font-size: 14px;

	font-weight: 500;

	margin-bottom: 0;

	margin-top: 3px;

}

.project-details-content h1 {

	font-size: 55px;

	font-weight: 500;

	text-transform: capitalize;

	line-height: 1;

	margin-bottom: 30px;

}

.project-details-content p{margin-bottom: 0;}





/* 9. team-area */

.team-wrapper {

	background: #fff;

	padding: 60px 30px;

	transition: .3s;

}

.team-wrapper:hover{box-shadow: 0px 16px 32px 0px rgba(208, 208, 208, 0.61);}

.single-team{box-shadow: 0px 16px 32px 0px rgba(208, 208, 208, 0.61);}

.single-1-team {

	box-shadow: none;

	border: 1px solid #ff5b67;

	padding: 31px 25px;

	margin-right: 70px;

}

.team-img {

	margin-bottom: 29px;

}

.team-text h3 {

	font-size: 24px;

	font-weight: 500;

	margin-bottom: 4px;

}

.team-text span {

	font-size: 14px;

	color: #ff5b67;

	font-weight: 400;

	text-transform: capitalize;

}

.team-icon {

	margin-top: 27px;

}

.team-icon a {

	width: 40px;

	height: 40px;

	line-height: 40px;

	text-align: center;

	border-radius: 50%;

	background: #f1f4ff;

	color: #a39cd6;

	display: inline-block;

	margin: 0 4px;

	transition: .3s;

	font-size: 14px;

}

.team-icon a:hover {

	background: #ff5b67;

	color: #fff;

}

.team-active.owl-carousel .owl-item img {

	display: inline-block;

	width: inherit;

}

.team-active .owl-nav div {

	background: #fff;

	font-size: 18px;

	left: -88px;

	opacity: 0;

	position: absolute;

	text-align: center;

	top: 50%;

	transition: all 0.3s ease 0s;

	visibility: hidden;

	color: #272063;

	font-weight: 400;

	height: 60px;

	width: 60px;

	line-height: 60px;

	border-radius: 50%;

	transform: translateY(-50%);

	box-shadow: 0px 3px 32px 0px rgba(208, 208, 208, 0.61);

}

.team-active .owl-nav div.owl-next {

	left: auto;

	right:-88px;

}

.team-active:hover .owl-nav div {

	opacity: 1;

	visibility: visible;

}

.team-active .owl-nav div:hover {

	background:#ff5b67;

	color:#fff;

}



/* 10. faq-area */

.faq-img {

	margin-left: -120px;

}

.faq-box .btn-link {

	font-weight: 500;

	color: #675eac;

	background-color: #f4f4fe;

	display: block;

	font-size: 14px;

	padding: 20px 40px;

	border: 0;

	text-decoration: none;

	line-height: 28px;

	font-family: 'Rubik', sans-serif;

	letter-spacing: -.3px;

}

.faq-box .card-header {

	padding: 0;

	border-bottom: 0;

	background: none;

	border-radius: 0;

}



.faq-box .card {

	position: relative;

	display: -webkit-box;

	display: -ms-flexbox;

	display: flex;

	-webkit-box-orient: vertical;

	-webkit-box-direction: normal;

	-ms-flex-direction: column;

	flex-direction: column;

	min-width: 0;

	word-wrap: break-word;

	background-color: #fff;

	background-clip: border-box;

	border: 0;

	border-radius: 0;

	background: #F8F7FF;

	margin-bottom: 20px;

}

.faq-box .card-body {

	-webkit-box-flex: 1;

	-ms-flex: 1 1 auto;

	flex: 1 1 auto;

	padding: 0 40px;

	padding-bottom: 25px;

}

.faq-box .card-body p {

	margin: 0;

	font-size: 14px;

	line-height: 28px;

}

.faq-box .btn-link.collapsed::before {

	content: "\f070";

}

.faq-box .btn-link::before {

	content: "\f06e";

	line-height: 30px;

	position: absolute;

	right: 40px;

	top: 20px;

	font-size: 16px;

	font-family: "Font Awesome 5 Pro";

	color: #675eb9;

}

.shape-item {

	position: absolute;

	z-index: 1;

}

.fa-01 {

	top: 7%;

	right: 17%;

}

.fa-02 {

	top: 16%;

	right: 7%;

}



/* 11. testimonial-area */

.testimonial-wrapper{position: relative;}

.testimonial-wrapper::before {

	color: #f0eff4;

	content: "\f10e";

	font-family: "Font Awesome 5 Pro";

	font-size: 120PX;

	font-weight: 400;

	position: absolute;

	text-align: center;

	line-height: 1;

	top: 77px;

	right: 147px;

	z-index: -1;

}

.testimonial-text p {

	font-size: 20px;

	line-height: 35px;

	margin-bottom: 0;

	color: #272063;

	margin-right: 10%;

}

.testimonial-text h4 {

	font-size: 18px;

	font-weight: 500;

	margin-top: 27px;

	margin-bottom: 1px;

}

.testimonial-text span {

	font-size: 14px;

	color: #ff5b67;

	font-weight: 400;

	letter-spacing: 1px;

}

.testimonial-img {

	margin-left: 10px;

}

.testimonial-active .owl-nav div {

	color: #9d98bf;

	font-size: 22px;

	position: absolute;

	left: 0;

	transition: all 0.3s ease 0s;

	font-weight: 400;

	bottom: -60px;

}

.testimonial-active .owl-nav div.owl-prev {

	left: 39px;

}

.testimonial-active .owl-nav div:hover{color:#ff5b67;}



/* testimonial-2-style */

.inner-client {

	box-shadow: 0px 10px 30px 0px rgba(153, 146, 214, 0.17);

	padding: 54px 70px 58px 70px;

	margin-right: 58px;

	margin-left: 58px;

	position: relative;

	background: #fff;

}

.inner-client::before {

	width: 0;

	height: 0;

	border-style: solid;

	border-width: 35px 15px 0 15px;

	border-color: #fff transparent transparent transparent;

	position: absolute;

	content: "";

	bottom: -35px;

	left: 0;

	right: 0;

	margin: auto;

}

.inner-client .testimonial-text p {

	font-size: 18px;

	line-height: 34px;

	margin-bottom: 0;

	color: #908aba;

	margin-right:0;

}

.client-img {

	box-shadow: 0px 8px 30px 0px rgba(95, 95, 95, 0.6);

	display: inline-block;

	border-radius: 50%;

	margin-top: 76px;

}

.client-active .owl-nav div {

	font-size: 18px;

	left: -200px;

	opacity: 0;

	position: absolute;

	text-align: center;

	top: 39%;

	transition: all 0.3s ease 0s;

	visibility: hidden;

	color: #ff5b67;

	font-weight: 400;

	height: 60px;

	width: 60px;

	line-height: 60px;

	border-radius: 50%;

	border: 2px solid #ff5b67;

}

.client-active .owl-nav div.owl-next {

	left: auto;

	right:-200px;

}

.client-active:hover .owl-nav div {

	opacity: 1;

	visibility: visible;

}

.client-active .owl-nav div:hover {

	background:#ff5b67;

	color:#fff;

	border-color:#ff5b67;

}

.client-active.owl-carousel .owl-item img {

	display: inline-block;

	width: inherit;

}



/* .testimonial-3-style */

.testimonial-2-wrapper {

	background: #fff;

	padding: 32px 35px 46px 35px;

	position: relative;

}

.testimonial-2-wrapper::before {

	color: #ff5b67;

	content: "\f10e";

	font-family: "Font Awesome 5 Pro";

	font-size: 36px;

	font-weight: 400;

	position: absolute;

	text-align: center;

	line-height: 1;

	bottom: 26px;

	right: 130px;

	opacity: .3;

}

.testimonial-2-name {

	border-bottom: 1px solid #e2e0f6;

	padding-bottom: 32px;

	margin-bottom: 30px;

}

.testimonial-2-img {

	float: left;

	margin-right: 20px;

	margin-top: -76px;

	position: relative;

}

.testimonial-2-text {

	overflow: hidden;

}

.testimonial-2-text h4 {

	font-size: 18px;

	font-weight: 500;

	margin-bottom: 0;

}

.testimonial-2-text span {

	font-size: 13px;

	color: #ff5b67;

	text-transform: uppercase;

	letter-spacing: 2px;

	font-weight: 500;

}

.testimonial-2-content p {

	margin-bottom: 0;

}

.testimonial-2-active .owl-dots {

	bottom: -69px;

	left: 0;

	position: absolute;

	right: 0;

	text-align: center;

	z-index: 99;

}

.testimonial-2-active .owl-dot {

	display: inline-block;

	height: 3px;

	margin: 0 5px;

	width: 15px;

	background: #d5e0ff;

	transition: .3s;

	border-radius: 3px;

}

.testimonial-2-active .owl-dot.active {

  position: relative;

  background:#ff5b67;

  width:30px;

}

.testimonial-3-wrapper{box-shadow: 0px 10px 30px 0px rgba(223, 211, 248, 0.46);background: #fff;}

.testimonial .section-title h1::before {

	display: none;

}

.testimonial .section-title h1 {

	padding-bottom: 0;

}

/* testimonial 3 */

.testimonial-item i {

    color: #ff5b67;

    font-size: 72px;

    margin-bottom: 40px;

}

.testimonial-item > p {

    font-size: 17px;

    line-height: 30px;

    margin-bottom: 25px;

}

.designation > h3 {

    font-size: 22px;

    font-weight: 600;

    margin-bottom: 7px;

}

.testimonial-nav {

  margin: 50px auto auto;

  text-align: center;

  width: 310px;

}

.testimonial-nav button {

  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;

  border: medium none;

  color: #333333;

  font-size: 20px;

  left: -50px;

  position: absolute;

  top: 50%;

  transform: translateY(-50%);

}

.testimonial-nav button.slick-next {

  left: auto;

  right: -50px;

}

.testimonial-thumb > img {

  transform: scale(0.7);

}

.testimonial-thumb.slick-center > img {

  transform: scale(01);

}



/* 12. cta-area */

.cta-area {

	background-size: cover;

	background-position: center center;

}

.cta-text h1 {

	color: #fff;

	font-size: 55px;

	line-height: 1;

	margin-bottom: 30px;

}

.cta-text p {

	color: #fff;

	margin-bottom: 32px;

}

.ct-2-text a.btn {

	color: #272063;

	background: #fff;

}

.ct-2-text a.btn:hover{background: #272063;color:#fff;}

.cta-img {

	margin-bottom: -160px;

}



/* 13. blog-area */

.blog-img > a img{width:100%;}

.blog-text {

	margin-top: 38px;

}

.blog-text > h4 {

	font-size: 20px;

	font-weight: 500;

	letter-spacing: -.3px;

	padding-right: 20px;

	line-height: 1.6;

	margin-bottom: 33px;

}

.blog-text > h4 a{color:#272063;transition: .3s;}

.blog-text > h4 a:hover{color:#ff5b67;}

.blog-meta span > a {

	background: #ff5b67;

	color: #fff;

	text-transform: uppercase;

	font-size: 13px;

	font-weight: 500;

	letter-spacing: 2px;

	font-family: 'Rubik', sans-serif;

	display: inline-block;

	padding: 4px 21px;

}

.blog-meta {

	margin-bottom: 30px;

}

.blog-meta span {

	color: #9d98bf;

	font-size: 14px;

	font-weight: 400;

	font-family: 'Rubik', sans-serif;

	margin-right: 17px;

}

.blog-meta span:last-child{margin-right:0;}

.blog-meta span i {

	color: #ff5b67;

	margin-right: 7px;

}

.blog-text > a.btn {

	background: #e5eafd;

	color: #252525;

	transition: .3s;

}

.blog-text > a.btn:hover{background: #ff5b67;color:#fff;}

.blog-standard .blog-text > h4 {

	font-size: 30px;

	line-height: 1.3;

}

/* blog-2-style */

.single-blog{background: #f4f7ff;}

.single-blog .blog-meta {

	margin-bottom: 38px;

	background: #fff;

	padding: 20px 20px 20px 25px;

	margin-top: -94px;

	z-index: 9;

	position: relative;

}

 .single-blog .blog-text {

	padding: 60px 30px 50px 30px;

	margin-top: 0;

}

.single-blog .blog-text > h4 {

	padding-right: 0;

}

.single-blog .blog-text > a.btn {

	background: #fff;

}

.single-blog .blog-text > a.btn:hover{

	background: #ff5b67;

}



/* pagination */

.basic-pagination ul {

  display: block;

}

.basic-pagination ul li {

  display: inline-block;

}

.basic-pagination ul li a {

	height: 50px;

	width: 50px;

	background: #f4f7ff;

	color: #272063;

	font-size: 14px;

	font-weight: 500;

	border-radius: 50%;

	line-height: 50px;

	margin: 0 4px;

	display: inline-block;

	text-align: center;

	font-family: 'Rubik', sans-serif;

}



.basic-pagination ul li a:hover {

  background: #ff5b67;

  color: #ffffff;

}

/* blog-sidebaer */

.widget {

	padding: 30px;

	background-color: rgb(255, 255, 255);

	box-shadow: 0px 8px 16px 0px rgba(200, 183, 255, 0.2);

	border-top: 4px solid #ff5b67;

}

.b-0 {

  border: 0;

}

.search-form {

  position: relative;

}

.search-form input {

	width: 100%;

	height: 60px;

	border: 0;

	background: #f5f5f5;

	padding: 0 20px;

}

.search-form button {

	position: absolute;

	top: 0;

	right: 0;

	height: 100%;

	background: #272063;

	padding: 0 25px;

	color: #ffffff;

	line-height: 60px;

	border: 0;

	transition: .3s;

	cursor: pointer;

}

.search-form button:hover {

  background: #ff5b67;

}

.widget-title {

  font-size: 21px;

  margin-bottom: 0px;

  position: relative;

  display: inline-block;

}



.animate-border::after {

  position: absolute;

  content: "";

  width: 35px;

  height: 3px;

  left: 0;

  bottom: 0;

  border-left: 10px solid #ffffff;

  border-right: 10px solid #ffffff;

  -webkit-animation: animborder 2s linear infinite;

  animation: animborder 2s linear infinite;

}

.animate-border.border-white::after {

  border-color: #ffffff;

}



.animate-border.border-white::after {

  border-color: #ffffff;

}

.about-me img {

	margin-bottom: 25px;

	border-radius: 50%;

}

.about-me h4 {

  font-size: 18px;

  letter-spacing: 1px;

}



.widget-social-icon a {

	display: inline-block;

	margin: 0 8px;

	color: #ff5b67;

}



.widget-social-icon a:hover {

  color: #ff5b67;

}

.banner-widget img {

	max-width: 100%;

}

.widget .recent-posts > li:first-child {

  border: 0;

  padding-top: 0;

}

.widget .recent-posts li:last-child {

  padding-bottom: 0;

}

.widget .recent-posts > li {

  border-top: 1px solid #eaedff;

  padding: 20px 0;

  overflow: hidden;

}

.widget-posts-image {

  float: left;

}

.widget-posts-image img {

  width: 80px;

  height: 80px;

  border-radius: 50%;

  margin-right: 15px;

}

.widget-posts-body {

  overflow: hidden;

}



.widget-posts-title {

	margin-bottom: 10px;

	font-size: 16px;

	line-height: 1.3;

}

.widget-posts-title a:hover {

  color: #ff5b67;

}

.widget-posts-meta {

  font-size: 12px;

}

ul.cat li {

  border-top: 1px solid #eaedff;

  padding: 15px 0;

  overflow: hidden;

}

ul.cat li:first-child {

  border-top: 0;

  padding-top: 0;

}

ul.cat li:last-child {

  padding-bottom: 0;

}

ul.cat li a {

	display: block;

	color: #647589;

}

ul.cat li a:hover {

  color: #ff5b67;

}

.social-profile a {

  height: 50px;

  width: 50px;

  text-align: center;

  background: #062a4d;

  line-height: 50px;

  margin-right: 2px;

  display: inline-block;

  color: #ffffff;

  margin-bottom: 5px;

}

.social-profile a:hover {

  background: #ff5b67;

}

#Instafeed {

  margin: 0 -5px;

  overflow: hidden;

}

#Instafeed li {

  width: 33.33%;

  float: left;

  padding: 0 5px;

  margin-bottom: 10px;

}

#Instafeed li img {

  width: 100%;

}

.tag a {

	display: inline-block;

	line-height: 1;

	padding: 12px 18px;

	background: #f5f5f5;

	margin-bottom: 8px;

	margin-right: 5px;

	text-transform: uppercase;

	font-size: 12px;

	font-weight: 500;

	color: #5f5b5b;

	border-radius: 2px;

}

.tag a:hover {

  background: #ff5b67;

  color: #ffffff;

}

.bg-none {

  background: none;

}

.blog-post-tag span {

  font-size: 18px;

  font-weight: 600;

  display: block;

  margin-bottom: 17px;

  font-family: "Poppins", sans-serif;

  color: #062a4d;

}

.blog-post-tag a {

  color: #646974;

  display: inline-block;

  font-size: 14px;

  font-weight: 500;

  text-transform: uppercase;

  border: 2px solid #eaedff;

  padding: 12px 24px;

  margin-right: 10px;

  transition: .3s;

  border-radius: 5px;

  margin-bottom: 10px;

}

.blog-post-tag a:hover {

  color: #fff;

  background: #ff5b67;

  border-color: #ff5b67;

}



/* 14. newsletters-area */

.newsletters-bg{

	background-size: cover;

	background-position: center center;

}

.newsletters-text h1 {

	font-size: 55px;

	font-weight: 300;

	margin-bottom: 45px;

	text-transform: capitalize;

	line-height: 1.1;

	letter-spacing: -3px;

	padding: 0 50px;

}

.newsletters-text h1 strong{font-weight: 500;}

.newsletters-text h1 strong span{color:#ff5b67;}

.newsletter-form  form {

  position: relative;

}

.newsletter-form form input {

	border: 0;

	height: 80px;

	padding: 0 75px;

	width: 100%;

	background: #fff;

	color: #9d98bf;

	font-size: 14px;

	border-radius: 30px;

}

.newsletter-form form input::placeholder {

    color: #9d98bf;

	font-size: 14px;

}

.newsletter-form form::-moz-placeholder {

  	color: #9d98bf;

	font-size: 14px;

}

.news-icon{position: relative;}

.news-icon::before {

	position: absolute;

	content: "\f2b6";

	font-family: "Font Awesome 5 Pro";

	font-weight: 500;

	display: inline-block;

	font-size: 14px;

	text-align: center;

	left: 40px;

	color: #9d98bf;

	top: 29px;

	z-index: 99;

}

.newsletter-form form button.btn {

	position: absolute;

	right: 18px;

	top: 13px;

	background: #272063;

	padding: 17px 44px;

}

.newsletter-form form button.btn:hover{background: #ff5b67;}



.newsletter-2-form form {

  position: relative;

}

.newsletter-2-wrapper .newsletter-2-form input {

	border: 0;

	height: 60px;

	padding: 0 60px;

	width: 100%;

	background: #fff;

	color: #9d98bf;

	font-size: 14px;

	border-radius: 30px;

}

.newsletter-2-form form input::placeholder {

    color: #9d98bf;

	font-size: 14px;

}

.newsletter-2-form form::-moz-placeholder {

  	color: #9d98bf;

	font-size: 14px;

}

.news2-icon{position: relative;}

.news2-icon::before {

	position: absolute;

	content: "\f2b6";

	font-family: "Font Awesome 5 Pro";

	font-weight: 500;

	display: inline-block;

	font-size: 14px;

	text-align: center;

	left: 32px;

	color: #9d98bf;

	top: 20px;

	z-index: 99;

}

.newsletter-2-form form button.btn {

	position: absolute;

	right: 7px;

	top: 6px;

	background: #ff5b67;

	padding: 14px 44px;

}

.newsletter-2-form form button.btn:hover{background: #272063;}



/* 15. footer */

.footer-area {

	background-size: cover;

	background-position: center center;

}

.footer-border{border-top:1px solid #dbe0f9;}

.footer-top-text p {

	margin-bottom: 0;

	line-height: 28px;

	margin-top: 17px;

}

.footer-title {

	font-size: 20px;

	font-weight: 500;

	position: relative;

	padding-bottom: 15px;

	margin-bottom: 33px;

}

.footer-title::before {

	background: #d3cdee;

	bottom: -1px;

	content: "";

	height: 2px;

	position: absolute;

	width: 40px;

}

.footer-menu {

  overflow: hidden;

}

.footer-menu li {

	padding-bottom: 7px;

	position: relative;

}

.footer-menu li:last-child{padding-bottom:0;}

.footer-menu li::before {

	position: absolute;

	content: "\f30b";

	left: 0;

	top: 3px;

	font-family: "Font Awesome 5 Pro";

	color: #9d98bf;

	font-size: 14px;

	transition: .3s;

	opacity: 0;

	font-weight: 300;

}

.footer-menu li:hover::before{opacity: 1;}

.footer-menu li a {

	color: #9290a3;

	font-size: 14px;

	font-weight: 400;

	transition: .3s;

}

.footer-menu li a:hover{color:#ff5b67;padding-left:20px;}

.footer-text h4 {

	font-size: 16px;

	line-height: 1.7;

	font-weight: 400;

	margin-bottom: 2px;

}

.footer-text h4 a{color:#272063;transition: .3s;}

.footer-text h4 a:hover{color:#ff5b67;transition: .3s;}

.footer-text span {

	font-size: 14px;

	color: #9290a3;

	font-weight: 400;

}

.footer-img {

	margin: 0 -4px;

}

.footer-img > li {

	float: left;

	overflow: hidden;

	padding: 4px;

	width: 33.33%;

	position: relative;

}

.footer-img a {

  position: relative;

  display: block;

}

.footer-img a img{width:100%;}

.footer-img a::before {

  background: #ff5b67;

  content: "";

  height: 100%;

  left: 0;

  opacity: 0;

  position: absolute;

  top: 0;

  width: 100%;

  transition:.3s;

}

.footer-img a:hover::before{opacity:.8;}

.footer-bottom-border{border-top:1px solid #dbe0f9;}



.copyright p {

	margin-bottom: 0;

}

.copyright p a{color:#ff5b67;}



/* footer-2-style */

.footer-2-text h1 {

	color: #fff;

	font-size: 55px;

	letter-spacing: -3px;

	line-height: 1;

	margin-bottom: 25px;

}

.footer-2-text span {

	color: #fff;

	display: block;

	font-size: 20px;

	padding-bottom: 8px;

	font-weight: 400;

}

.footer-2-text span:last-child{padding-bottom: 0;}

.footer-icon {

	margin-top: 30px;

}

.footer-icon a {

	color: #a39cd6;

	font-size: 13px;

	height: 40px;

	width: 40px;

	line-height: 40px;

	display: inline-block;

	background: #362e7c;

	text-align: center;

	border-radius: 50%;

	margin-right: 7px;

	transition: .3s;

}

.footer-icon a:hover{background:#ff5b67;color:#fff;}

.footer-middle .footer-title {

	color: #fff;

}

.footer-middle .footer-menu li a {

	color: #a8a2df;

	transition: .3s;

}

.footer-middle .footer-menu li a:hover{

	color: #ff5b67;

}

.footer-middle .footer-menu li::before {

	color: #ff5b67;

}

.footer-bottom-link li {

	display: inline-block;

	padding-right: 25px;

}

.footer-bottom-link li:last-child{padding-right: 0;}

.footer-bottom-link li a {

	color: #a8a2df;

	font-size: 14px;

	font-weight: 400;

	transition: .3s;

	position: relative;

	font-family: 'Rubik', sans-serif;

}

.footer-bottom-link li a:hover{color:#ff5b67;}

.footer-bottom-border-2{border-top:1px solid #3d3584;}

.footer-2-border {

	border-top: 1px solid #3d3584;

}

.footer-bottom-border-3{border-top:1px solid #feeaec;}

.footer-2-border {

	border-top: 1px solid #3d3584;

}

.footer-3-border {

	border-top: 1px solid #feeaec;

}



/* 16. about-area */

.about-wrapper {

	padding-right: 68px;

}

.area-title span {

	color: #ff5b67;

	text-transform: uppercase;

	font-family: 'Rubik', sans-serif;

	font-weight: 500;

	letter-spacing: 2px;

	display: inline-block;

	margin-bottom: 24px;

}

.area-border{position: relative;}

.area-border::before {

	position: absolute;

	background: #b9b3ea;

	width: 40px;

	height: 2px;

	padding: 0;

	position: absolute;

	content: "";

	right: -58px;

	top: 10px;

}

.area-title h1 {

	font-size: 55px;

	font-weight: 500;

	line-height: 1.1;

	margin-bottom: 0;

	letter-spacing: -3px;

}

.area-title h1 strong {

	color: #ff5b67;

	font-weight: 500;

}

.area-title-white h1{color:#fff;}

.area-title-white p{color:#fff;margin-bottom: 0;}

.area-title-white p {

	color: #fff;

	margin-bottom: 0;

	margin-top: 24px;

}

.area-title p {

	margin-bottom: 0;

	margin-top: 24px;

	line-height: 32px;

}

.about-text a.btn {

	margin-top: 42px;

}

.about-img {

	margin-left: 35px;

}

.area-border{position: relative;}

.ab-01 {

	top: 1%;

	left: 18%;

}



/* 17. study-area */

.study-area {

	background-position: right;

	background-repeat: no-repeat;

}

.study-img > a img{width:100%;}

.study-text {

	box-shadow: 0px 16px 32px 0px rgba(199, 199, 199, 0.3);

	padding: 35px 16px 35px 19px;

	background: #fff;

}

.study-text h4 {

	font-size: 20px;

	font-weight: 500;

	margin-bottom: 0;

	line-height: 1.4;

}

.study-text h4 > a{color:#272063;}



/* 18. pricing-area */

.pricing-area {

	background-size: cover;

	background-position: center center;

}

.pricing-tab {

	box-shadow: 0px 10px 30px 0px rgba(153, 146, 214, 0.27);

	display: inline-block;

	background: #fff;

	border-radius: 30px;

}

.pricing-tab li{display:inline-block}

.pricing-tab li a {

	color: #272063;

	text-transform: uppercase;

	padding: 16px 42px;

	border-radius: 30px;

	font-size: 13px;

	font-weight: 500;

	transition: .3s;

	letter-spacing: 2px;

}

.pricing-tab li a.active {

	background: #ff5b63;

	color:#fff;

}

.pricing-tab li a:hover {

	background: #ff5b63;

	color:#fff;

}

.pricing-bg {

	padding-left: 40px;

}

.pricing-wrapper {

	background: #fff;

	padding: 60px 38px 50px 30px;

	transition: .3s;

	position: relative;

	background-repeat: no-repeat;

}

.pricing-wrapper:hover{box-shadow: 0px 10px 30px 0px rgba(153, 146, 214, 0.27);}

.pricing-wrapper.active{box-shadow: 0px 10px 30px 0px rgba(153, 146, 214, 0.27);}

.pricing-header{position: relative;}

.price-count {

	position: absolute;

	right: 3px;

	top: -20px;

}

.price-count h1 {

	font-size: 30px;

	font-weight: 500;

	line-height: 1;

	margin-bottom: 10px;

	color: #fff;

}

.price-count h1 span.usd {

	font-size: 18px;

	position: relative;

	color: #ff5b67;

	right: 0;

	bottom: 0;

}

.price-count h5 {

	font-weight: 500;

	color: #ff5b67;

	letter-spacing: 2px;

	text-transform: uppercase;

	font-size: 13px;

	margin-bottom: 0;

}

.pricing-list {

	margin-top: 63px;

	margin-bottom: 45px;

}

.pricing-list ul li {

	font-size: 14px;

	color: #9d98bf;

	font-weight: 400;

	padding-bottom: 23px;

}

.pricing-list ul li:last-child{padding-bottom: 0;}

.pricing-list ul li i {

	color: #272063;

	padding-right: 3px;

}

.pricing-list li.price-list i {

	color: #ff5b67;

}

.pricing-button a.btn{

	background:#f0f1ff;

	color:#272063;

	transition: .3s;

}

.pricing-button a.btn:hover{

	background:#ff5b67;

	color:#fff;

}

.pricing-button a.btn.active{

	background:#ff5b67;

	color:#fff;

}

.pricing-wrapper.active .price-count h1 span.usd {

	color: #fff;

}

.pricing-wrapper.active .price-count h5 {

	color: #fff;

}



/* pricing-2-style */

.single-pricing {

	border: 1px solid #e8e6f9;

	padding: 50px 40px 56px 40px;

}

.single-pricing.active {

	position: relative;

	margin-top: -29px;

}

.single-pricing.active::before {

	background-image: url("../img/icon/pricing.png");

	content: "";

	height: 70px;

	position: absolute;

	transition: all 0.3s ease 0s;

	width: 70px;

	top: -36px;

	right: 20px;

}

.pricing-top-text {

	margin-bottom: 35px;

}

.pricing-top-text h3 {

	font-size: 24px;

	text-transform: uppercase;

	font-weight: 500;

	letter-spacing: -.3px;

	margin-bottom: 0;

	color:#1a237e;

}

.pricing-top-text p{margin-bottom: 0;}

.pricing-2-list {

	margin-top: 41px;

	padding-left: 20px;

}

.pricing-2-list ul li {

	font-size: 18px;

	color: #6e6e6e;

	font-weight: 400;

	padding-bottom: 30px;

}

.pricing-2-list ul li:last-child{padding-bottom: 0;}

.pricing-2-list ul li i {

	color: #6e6e6e;

	padding-right: 12px;

}

.price-2-count{

	padding-top: 40px;

	padding-bottom: 60px;

}

.price-2-count h1 {

	font-size: 40px;

	font-weight: 500;

	line-height: 1;

	margin-bottom: 3px;

	color: #fff;

}

.price-2-count h5 {

	font-weight: 400;

	color: #fff;

	font-size: 20px;

	margin-bottom: 0;

}

.pricing-2-button{margin-top: -29px;}

.pricing-2-button a.btn {

	box-shadow: 0px 0px 30px 0px rgba(236, 91, 98, 0.5);

	background: #fff;

	color: #272063;

	transition: .3s;

}

.pricing-2-button a.btn:hover{background: #272063;color:#fff;}



/* 19. download-area */

.download-img {

	margin-left: -40px;

}

.download-wrapper {

	padding-left: 72px;

	margin-top: 88px;

}

.download-wrapper .area-title p {

	margin-top: 35px;

}

.download-app {

	margin-top: 41px;

}

.download-app a.btn {

	padding: 14px 41px;

}

.download-app a.btn.active {

	background: #272063;

	padding: 14px 38px;

	margin-left: 7px;

}

.download-app a i {

	float: left;

	font-size: 30px;

	position: relative;

	transition: .3s;

	top: 4px;

}

.download-app a p {

	margin: 0;

	line-height: 1.4;

	float: left;

	padding-left: 6px;

	color: #fff;

	text-align: left;

	transition: .3s;

	font-size: 13px;

	text-transform: capitalize;

	font-weight: 400;

	letter-spacing: 0;

	font-family: 'Poppins', sans-serif;

}

.download-app a p b {

	font-family: 'Rubik', sans-serif;

	letter-spacing: 2px;

	font-weight: 500;

	text-transform: uppercase;

	font-size: 13px;

}



/* 20. choose-area */

.choose-img {

	margin-top: 20px;

}

.choose-link {

	margin-bottom: 42px;

	margin-top: 21px;

}

.choose-link li {

	display: flex;

	padding-bottom: 14px;

}

.choose-link li:last-child{padding-bottom:0;}

.choose-text span {

	font-size: 14px;

	line-height: 30px;

	color: #938ebb;

	overflow: hidden;

}

.choose-icon {

	float: left;

	margin-right: 30px;

	margin-top: 10px;

}

.choose-icon i {

	color: #fff;

	font-size: 12px;

	background: #ff5b67;

	height: 35px;

	width: 35px;

	display: inline-block;

	border-radius: 50%;

	text-align: center;

	line-height: 37px;

	transition: .3s;

}

.choose-link li:hover .choose-icon i{background:#272063;color: #fff;}

.choose-content a.btn{background: #272063;}

.choose-content a.btn:hover{background: #ff5b67;}

.ab-01 {

	top: -6%;

	left: 18%;

}

.ch-01 {

	top: 34%;

	left: 0;

	z-index: -1;

}

.rec-01 {

	top: -140px;

	right: 0;

}

/* 21. breadcrumb-area */

.breadcrumb-area {

  background-position: center center;

  background-size: cover;

}

.breadcrumb-text {

    position: relative;

    z-index: 1;

}

.breadcrumb-text > h1 {

	font-size: 85px;

	margin-bottom: 17px;

	text-transform: capitalize;

	line-height: 1;

	font-weight: 500;

}

.breadcrumb-menu li {

	display: inline-block;

	position: relative;

	padding: 0 23px;

}

.breadcrumb-menu li a {

	color: #ff5b67;

	font-size: 24px;

	font-weight: 400;

	text-transform: capitalize;

	transition: all 0.3s ease 0s;

}

.breadcrumb-menu li::before {

	content: "";

	display: inline-block;

	left: -6px;

	padding-bottom: 0;

	position: absolute;

	top: 20px;

	font-weight: 400;

	height: 5px;

	width: 5px;

	background: #272063;

	border-radius: 50%;

}

.breadcrumb-menu li:first-child::before {

    display: none;

}

.breadcrumb-menu span {

	color: #675eac;

	font-size: 24px;

	font-weight: 400;

	text-transform: capitalize;

}



/* 22. services-details */

.services-details-img img{width:100%;}

.services-details-content h1 {

	color: #1a237e;

	font-size: 55px;

	line-height: 1;

	margin-bottom: 25px;

}

.services-details-content p {

	margin-bottom: 0;

}

.services-details-text p{margin-bottom: 0;}

.services-video-area {

	background-size: cover;

	background-position: center center;

}

.video-icon a {

	color: #ff5b67;

	font-size: 18px;

	height: 100px;

	width: 100px;

	background: #fff;

	display: inline-block;

	border-radius: 50%;

	text-align: center;

	line-height: 100px;

	transition: .3s;

}

.video-icon a:hover{background: #ff5b67;color: #fff;}



/* 23. blog-details */

.blog-details-wrapper blockquote {

	background: #062a4d;

	padding: 50px;

	position: relative;

	padding-left: 120px;

	margin-bottom: 30px;

	margin-top: 30px;

}

.blog-details-wrapper blockquote p {

	font-size: 26px;

	margin-bottom: 20px;

	color: #ffffff;

	font-weight: 500;

	line-height: 1.3;

}



.blog-details-wrapper blockquote footer {

  font-size: 14px;

  letter-spacing: 2px;

  color: #ffffff;

  font-weight: 500;

  text-transform: uppercase;

}



.blog-details-wrapper blockquote::before {

  content: "\f10d";

  left: 50px;

  top: 50px;

  position: absolute;

  color: #758799;

  font-family:"Font Awesome 5 Pro";

  font-weight: 700;

  font-size: 41px;

}

.blog-share-icon span {

  font-size: 18px;

  font-weight: 600;

  font-family: "Poppins", sans-serif;

  color: #062a4d;

  display: block;

  margin-bottom: 17px;

}



.blog-share-icon a {

  color: #6f7172;

  font-size: 18px;

  transition: .3s;

  margin-right: 22px;

}



.blog-share-icon a:hover {

  color: #ff5b67;

}



.blog-share-icon a:last-child {

  margin-right: 0;

}



.blog-details-border {

  border-top: 1px solid #eaedff;

  border-bottom: 1px solid #eaedff;

  padding-top: 33px;

  padding-bottom: 23px;

  margin-bottom: 42px;

}

.author {

	background: #F4F7FC;

	padding: 0 60px 44px 60px;

}

.author-img {

  margin-bottom: 35px;

}



.author-img img {

  margin-top: -60px;

}

.author-text h3 {

	font-size: 24px;

	margin-bottom: 15px;

}

.author-text p {

	margin-bottom: 0;

	font-size: 15px;

	line-height: 28px;

}



.author-icon {

  margin-top: 6px;

  margin-bottom: 25px;

}



.author-icon a {

  font-size: 14px;

  color: #9498b6;

  margin: 0 5px;

  transition: .3s;

}



.author-icon a:hover {

  color: #ff5b67;

}



.post-comments {

  border-bottom: 1px solid #eaedff;

  margin-bottom: 42px;

  padding-bottom: 46px;

}



.blog-coment-title h2 {

  font-size: 26px;

  margin-bottom: 0;

}



.comments-avatar {

  float: left;

}



.comments-avatar img {

  width: 100px;

  margin-right: 20px;

}



.latest-comments li:first-child .comments-box {

  border-top: 0;

  padding-top: 0;

}



.latest-comments li:last-child .comments-box {

  border-bottom: 0;

  padding-bottom: 0;

}



.latest-comments li.children {

  margin-left: 100px;

}

.comments-box {

  border-top: 1px solid #eaedff;

  padding: 30px 0;

}



.comments-text {

  overflow: hidden;

  padding-top: 4px;

}

.comments-text p {

  margin-bottom: 0;

  margin-top: 8px;

}



.avatar-name h5 {

  font-size: 18px;

  margin-bottom: 7px;

}



.avatar-name span {

  color: #ff5b67;

  font-size: 12px;

  font-weight: 500;

  text-transform: uppercase;

  letter-spacing: 2px;

}



.avatar-name {

  margin-bottom: 3px;

  overflow: hidden;

  position: relative;

}



.reply {

  color: #6f7172;

  display: inline-block;

  font-size: 14px;

  font-weight: 400;

  transition: .3s;

  line-height: 1;

  margin-top: 4px;

  position: absolute;

  right: 0;

  top: 0;

}

.reply:hover {

  color: #ff5b67;

}

.reply i {

  margin-right: 5px;

}

.post-comments-title {

  margin-bottom: 39px;

}

.post-comments-title h2 {

	font-size: 26px;

	color: #140a20;

	margin-bottom: 0;

}

.conatct-post-form {

	background: #F4F7FC;

	padding: 50px;

}

.conatct-post-form input {

  border: none;

  height: 60px;

  margin-bottom: 20px;

  padding: 0 30px;

  width: 100%;

  font-size: 14px;

  color: #a7a7c1;

}

.conatct-post-form textarea {

  border: none;

  height: 150px;

  margin-bottom: 20px;

  padding: 30px;

  width: 100%;

  color: #a7a7c1;

  font-size: 14px;

}

#contacts-post-form input::-moz-placeholder {

  color: #a7a7c1;

  font-size: 14px;

}

#contacts-post-form input::placeholder {

  color: #a7a7c1;

  font-size: 14px;

}

#contacts-post-form textarea::-moz-placeholder {

  color: #a7a7c1;

  font-size: 14px;

}

#contacts-post-form textarea::placeholder {

  color: #a7a7c1;

  font-size: 14px;

}

.contact-icon {

  position: relative;

}

.contact-icon::after {

  position: absolute;

  content: "";

  font-family: "Font Awesome 5 Pro";

  font-weight: 700;

  display: inline-block;

  font-size: 16px;

  text-align: center;

  right: 30px;

  color: #ff5b67;

  top: 18px;

}

.conatct-post-form .contact-icon::before{

	display: none;

}

.contacts-message::after {

  content: "\f303";

}



.contacts-name::after {

  content: "\f007";

}



.contacts-email::after {

  content: "\f0e0";

}

.contacts-website::after {

  content: "\f122";

}

.navigation-border {

	border-top: 2px solid #f7f7f7;

	overflow: hidden;

}



.navigation-border {

  border-top: 2px solid #f7f7f7;

  overflow: hidden;

}



.bakix-navigation span {

  font-size: 14px;

  text-transform: uppercase;

  letter-spacing: 2px;

  font-weight: 500;

  display: block;

  margin-bottom: 10px;

}

.bakix-navigation span a {

	color: #444;

}

.bakix-navigation span a:hover {

  color: #ff5b67;

}

.bakix-navigation h4 {

  font-size: 24px;

  margin: 0;

}

.bakix-navigation h4 a:hover {

  color: #ff5b67;

}

.blog-inner-img img {

	width: 100%;

}



/* 24. map */

.contact-map {

	height: 700px;

	width: 100%;

}

/* 25. contact */

.contact-address-title h3 {

	background: #ff5b67;

	font-size: 24px;

	color: #fff;

	margin-bottom: 0;

	text-align: center;

	padding: 20px 10px;

	line-height: 1;

	border-left: 3px solid #272063;

	border-top: 3px solid #272063;

}

.contact-adddress-icon {

	float: left;

	margin-right: 30px;

}

.contact-adddress-icon i {

	height: 80px;

	width: 80px;

	color: #272063;

	font-size: 26px;

	line-height: 90px;

	text-align: center;

	background: #f1f6fa;

	display: inline-block;

	border-radius: 50%;

}

.contact-address-text {

	overflow: hidden;

}

.contact-address-text h5 {

	color: #ff5b67;

	letter-spacing: 2px;

	font-weight: 500;

	font-size: 14px;

	text-transform: uppercase;

	margin-bottom: 11px;

}

.contact-address-text p {

	font-size: 16px;

	line-height: 28px;

	margin-bottom: 0;

}

.contact-list-address li {

	padding-bottom: 52px;

}

.contact-list-address li:last-child{padding-bottom: 0;}

#contact-form input {

	height: 60px;

	width: 100%;

	border: none;

	margin-bottom: 30px;

	padding: 0 25px;

	transition: .3s;

	background: #f1f6fa;

	text-transform: capitalize;

}

#contact-form textarea {

	width: 100%;

	border: none;

	padding: 25px;

	height: 180px;

	margin-bottom: 30px;

	transition: .3s;

	background: #f1f6fa;

	text-transform: capitalize;

}

.contact-form input::-moz-placeholder, .contact-form input::placeholder {

  color: #8a8a8a;

}



.contact-form textarea::-moz-placeholder, .contact-form textarea::placeholder {

  color: #8a8a8a;

}

#contact-form .btn {

	cursor: pointer;

	border-radius: 0;

	transition: .3s;

	padding: 20px 58px;

}



/* 26. shop-area */

.product-showing p {

	margin: 0;

	margin-top: 11px;

}

.pro-filter select {

	border: 2px solid #eaedff;

	padding: 17px 30px;

	display: inline-block;

	text-transform: uppercase;

	font-size: 12px;

	letter-spacing: 2px;

	line-height: 1;

	color: #6f7172;

	appearance: none;

	-moz-appearance: none;

	width: 180px;

	font-weight: 500;

}

.product-img {

	margin-bottom: 22px;

	position: relative;

}

.product-img > a img {width: 100%;}

.product-action {

  position: absolute;

  left: 0;

  right: 0;

  bottom: 30px;

}

.product-action a {

	height: 50px;

	display: inline-block;

	width: 50px;

	background: #ff5b67;

	line-height: 50px;

	color: #fff;

	margin: 0 8px;

	opacity: 0;

	visibility: hidden;

	border-radius: 50%;

}

.product-action a:hover {

  background: #272063;

  color: #ffffff;

}

.product-wrapper:hover .product-action a {

  margin: 0 5px;

  opacity: 1;

  visibility: visible;

}

.on-sale span {

	position: absolute;

	top: 20px;

	left: 20px;

	font-size: 14px;

	font-weight: 600;

	background: #ef8829;

	color: #fff;

	padding: 3px 13px;

	border-radius: 30px;

}

.product-text h4 {

	font-size: 18px;

	margin-bottom: 0;

	text-transform: capitalize;

	font-weight: 500;

	letter-spacing: -.3px;

}

.product-text h4 > a {

	transition: .3s;

}

.product-text h4 > a:hover{color:#ff5b67;}

.pro-rating {

	margin-bottom: 4px;

}

.pro-rating i {

	font-size: 14px;

	color: #df4243;

	padding: 0 3px;

}

.pro-price span {

	font-size: 14px;

	color: #222222;

	font-weight: 400;

	font-family: 'Rubik', sans-serif;

}

/* details */

.shop-thumb-tab ul {

	margin: 0 -10px;

}

.product-large-img img {

	width: 100%;

}

.shop-thumb-tab li {

	width: 33.33%;

	float: left;

	padding: 0 10px;

}

.shop-thumb-tab li img {

	width: 100%;

}

.shop-thumb-tab li a {

	padding: 0;

}

.details-price span {

	margin-right: 10px;

	color: #222;

	font-size: 20px;

}

.details-price span.old-price {

	color: #c4cfd8;

	text-decoration: line-through;

}

.product-details-title h1 {

	margin-bottom: 12px;

}

.product-social a {

	margin-right: 10px;

	background: #f5f5ff;

	height: 50px;

	width: 50px;

	line-height: 48px;

	border-radius: 30px;

	color: #6f7172;

	display: inline-block;

	text-align: center;

	font-size: 14px;

}

.product-social a:hover {

	background: #ff5b67;

	color: #fff;

}

.cart-plus-minus input {

	height: 58px;

	width: 100px;

	border: 0;

	border: 2px solid #eaedff;

	text-align: center;

	-moz-appearance: none;

	appearance: none;

}

.plus-minus {

	display: inline-block;

	margin-right: 10px;

	position: relative;

	top: 1px;

}

.pro-rating.mb-20 {

	margin-bottom: 20px;

}

.product-details p {

	margin-bottom: 25px;

}

.pro-filter select {

	border: 2px solid #eaedff;

	padding: 17px 30px;

	display: inline-block;

	text-transform: uppercase;

	font-size: 12px;

	letter-spacing: 2px;

	line-height: 1;

	color: #6f7172;

	appearance: none;

	-moz-appearance: none;

	width: 180px;

	font-weight: 500;

}

.product-showing p {

	margin: 0;

	margin-top: 11px;

}

/* review */

.nav.review-tab {

  border-bottom: 1px solid #e5e5e5;

}

.review-tab .nav-link {

  color: #333;

  font-size: 18px;

  font-weight: 500;

  padding: 0;

  padding-bottom: 15px;

}

.review-tab .nav-item {

  margin-right: 65px;



   position: relative

}

.review-tab .nav-link.active::before {

  background: #ff5b67;

  bottom: -1px;

  content: "";

  height: 2px;

  position: absolute;

  transition: all 0.3s ease 0s;

  width: 100%;

}

.pro-comments-img {

  float: left;

}

.pro-commnets-text {

	overflow: hidden;

	padding-left: 15px;

}

.pro-commnets-text h4 {

	font-size: 17px;

	margin-bottom: 0;

}

.pro-commnets-text span {

  font-size: 14px;

}

.pro-commnets-text .rating {

  margin-bottom: 20px;

}

.pro-commnets-text > p {

  margin: 0;

}

.product-commnets-list {

  border-bottom: 1px solid #e5e5e5;

  padding-bottom: 40px;

}

.pro-commnets-text .pro-rating {

	margin-bottom: 5px;

	margin-top: 8px;

}

.product-commnets .product-commnets-list:last-child{border: 0}



.review-box > h4 {

  font-size: 18px;

  font-weight: 500;

  margin-bottom: 10px;

}

.rating-list {

  display: inline-block;

}

.your-rating > span {

  color: #414149;

  font-size: 16px;

}

.rating-list > a {

  color: #646464;

  font-size: 13px;

}

.review-form label {

  color: #414149;

  display: block;

  font-size: 16px;

  margin-bottom: 15px;

}

.review-form textarea {

  border: 1px solid #eaeaea;

  height: 80px;

  margin-bottom: 25px;

  padding: 15px;

  width: 100%;

}

.review-form input {

  border: 1px solid #eaeaea;

  height: 40px;

  margin-bottom: 30px;

  padding: 0 15px;

  width: 100%;

}



.footer-2-text a

{

	color: #9d98bf;

}



.footer-2-text a:hover

{

	color: #ff5b67;

}

.ssafield
        {
          display: none;
        }
        
        .ssafield.show
        {
            display: flex !important;
            align-items: center;
            justify-content: center;
            position: fixed;
            width: 100%;
            height: 100vh;
            left: 0;
            top:0;
            background: rgba(255, 255, 255, 0.6);
            z-index: 99999999999999999999;
        }
        
        
        .ssafield.show >div
        {
            position: relative;
            
            
        }
        .ssafield.show >div > .cross
        {
            width: 30px;
            height: 30px;
            position: absolute;
            right: -60px;
            top:0;
            background: white;
            border-radius: 2px;
            cursor: pointer;
            
        }
         .ssafield.show >div > .cross >div
        {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;        
            justify-content: center;
            width: 100%;
            height: 100%;
        }
        
        .ssafield.show >div > .cross >div>div
        {
            width: 80%;
            height: 2px;
            background: black;
            position: absolute;
            
        }
        
        .ssafield.show >div > .cross >div>div.l1
        {
            transform: rotate(45deg)
        }
         .ssafield.show >div > .cross >div>div.l2
        {
            transform: rotate(-45deg)
        }
		.p_names{
			font-size: 18px;
			font-weight: 500;
			margin-bottom: 0;
			color: #272063;
		}
		.h2_section{
			font-size: 55px;
			color: #675eac;
			font-weight: 300;
			line-height: 1.1;
			margin-bottom: 0;
			letter-spacing: -3px;
			position: relative;
			font-family: Rubik,sans-serif;
		}
		.half_bold_h2{
			font-size: 45px;
			font-weight: 500;
			line-height: 1.1;
			margin-bottom: 0;
			letter-spacing: -3px;
		}
		.section-about {
			margin-bottom: 0;
			margin-top: 46px;
		}














































